#wrapper h2 {
  font-size: 20px;
  font-weight: bold;
  position: relative;
  margin-bottom: 10px;
  letter-spacing: 3px;
  color: #f47907;
}
@media screen and (max-width: 767px) {
  #wrapper h2 {
    font-size: 18px;
    margin-bottom: 5px;
    letter-spacing: 2.5px;
  }
}
#wrapper h3 {
  font-size: 45px;
  font-weight: bold;
  line-height: 1.4;
  position: relative;
  margin-bottom: 30px;
  letter-spacing: 4px;
  color: #008b45;
}
@media screen and (max-width: 1024px) {
  #wrapper h3 {
    font-size: 38px;
  }
}
@media screen and (max-width: 767px) {
  #wrapper h3 {
    font-size: 21px;
    margin-bottom: 15px;
    letter-spacing: 2.5px;
  }
}
#wrapper h3 + p.txt {
  font-size: 18px;
  line-height: 1.8;
  letter-spacing: 2px;
}
@media screen and (max-width: 767px) {
  #wrapper h3 + p.txt {
    font-size: 14px;
    letter-spacing: 1.5px;
  }
}
#wrapper .kv_area {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 95px);
}
@media screen and (max-width: 767px) {
  #wrapper .kv_area {
    height: auto;
  }
}
#wrapper .kv_area video,
#wrapper .kv_area > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#wrapper .kv_area p {
  line-height: 1.8;
  position: absolute;
  bottom: 70px;
  left: 7%;
  white-space: nowrap;
}
@media screen and (max-width: 1024px) {
  #wrapper .kv_area p {
    left: 5%;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .kv_area p {
    bottom: 30px;
  }
}
#wrapper .kv_area p span {
  font-size: 46px;
  font-weight: bold;
  padding: 0 5px;
  letter-spacing: 5px;
  color: #fff;
  background: #008b45;
}
@media screen and (max-width: 1024px) {
  #wrapper .kv_area p span {
    font-size: 40px;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .kv_area p span {
    font-size: 22px;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .kv_area p img {
    width: 80%;
  }
}
#wrapper .lead_area {
  margin: 150px auto;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  #wrapper .lead_area {
    margin: 100px auto;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .lead_area {
    margin: 70px auto;
  }
}
#wrapper .lead_area h1 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.8;
}
@media screen and (max-width: 1024px) {
  #wrapper .lead_area h1 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .lead_area h1 {
    font-size: 18px;
  }
}
#wrapper .lead_area h1 span {
  font-size: 34px;
  color: #008b45;
}
@media screen and (max-width: 1024px) {
  #wrapper .lead_area h1 span {
    font-size: 30px;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .lead_area h1 span {
    font-size: 24px;
  }
}
#wrapper .philosophy_area {
  display: flex;
  width: 100%;
  margin-bottom: 180px;
  padding-right: 3%;
}
@media screen and (max-width: 1024px) {
  #wrapper .philosophy_area {
    position: relative;
    display: block;
    padding: 0;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .philosophy_area {
    margin-bottom: 90px;
  }
}
#wrapper .philosophy_area .img {
  width: 50%;
  min-height: 500px;
  padding-right: 90px;
  text-align: left;
  order: -1;
}
@media screen and (max-width: 1024px) {
  #wrapper .philosophy_area .img {
    width: 100%;
    min-height: 0;
    padding: 0;
  }
}
#wrapper .philosophy_area .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#wrapper .philosophy_area .txt_area {
  position: relative;
  width: 100%;
  max-width: 600px;
  padding-top: 60px;
}
@media screen and (max-width: 1024px) {
  #wrapper .philosophy_area .txt_area {
    max-width: 90%;
    margin: 0 auto 50px;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .philosophy_area .txt_area {
    padding-top: 30px;
  }
}
#wrapper .philosophy_area .txt_area p.deco {
  font-size: 84px;
  font-weight: 600;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
  letter-spacing: 3px;
  pointer-events: none;
  color: #f4f6f4;
  text-shadow: none;
}
@media screen and (max-width: 767px) {
  #wrapper .philosophy_area .txt_area p.deco {
    font-size: 12vw;
  }
}
#wrapper .philosophy_area .txt_area h2 {
  font-size: 18px;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  #wrapper .philosophy_area .txt_area h2 {
    margin-bottom: 5px;
  }
}
#wrapper .philosophy_area .txt_area h3 {
  font-size: 45px;
  line-height: 1;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  #wrapper .philosophy_area .txt_area h3 {
    font-size: 21px;
    line-height: 1.4;
    margin-bottom: 15px;
  }
}
#wrapper .philosophy_area .txt_area p.txt {
  font-weight: bold;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  #wrapper .philosophy_area .txt_area p.txt {
    margin-bottom: 30px;
  }
}
#wrapper .service_area {
  overflow: hidden;
  width: 100%;
  margin-bottom: 180px;
  padding: 90px 3% 150px;
  background: url(/index/img/service_bg.gif) center bottom repeat-x #f4f6f4;
}
@media screen and (max-width: 1024px) {
  #wrapper .service_area {
    padding: 90px 5% 180px;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .service_area {
    margin-bottom: 90px;
    padding-bottom: 90px;
    background-size: 44px;
  }
}
#wrapper .service_area > .inner {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
}
@media screen and (max-width: 767px) {
  #wrapper .service_area > .inner {
    padding-top: 10px;
  }
}
#wrapper .service_area > .inner p.deco {
  font-size: 180px;
  font-weight: 600;
  line-height: 1;
  position: absolute;
  top: -25px;
  right: -40px;
  text-indent: 20px;
  letter-spacing: 20px;
  pointer-events: none;
  color: #fdfdfd;
}
@media screen and (max-width: 1024px) {
  #wrapper .service_area > .inner p.deco {
    font-size: 120px;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .service_area > .inner p.deco {
    font-size: 15.3846153846vw;
    right: calc(5% - 10px);
    letter-spacing: 10px;
  }
}
#wrapper .service_area > .inner > p.txt {
  margin-bottom: 110px;
}
@media screen and (max-width: 767px) {
  #wrapper .service_area > .inner > p.txt {
    margin-bottom: 70px;
  }
}
#wrapper .service_area > .inner ul {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (max-width: 1024px) {
  #wrapper .service_area > .inner ul {
    justify-content: space-between;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .service_area > .inner ul {
    display: block;
  }
}
#wrapper .service_area > .inner ul li {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 50px 40px 40px;
  text-align: center;
  border-radius: 5px;
  background: #fff;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 1025px) {
  #wrapper .service_area > .inner ul li:not(:last-child) {
    margin-right: 3.3333333333%;
  }
  #wrapper .service_area > .inner ul li:nth-child(3n) {
    margin-right: 0;
  }
  #wrapper .service_area > .inner ul li:nth-child(n+4) {
    margin-top: 100px;
  }
}
@media screen and (max-width: 1024px) {
  #wrapper .service_area > .inner ul li {
    width: 48%;
  }
  #wrapper .service_area > .inner ul li:nth-child(n+3) {
    margin-top: 80px;
  }
}
@media screen and (max-width: 1024px) and (max-width: 767px) {
  #wrapper .service_area > .inner ul li:nth-child(n+3) {
    margin-top: 0;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .service_area > .inner ul li {
    width: 100%;
    padding: 40px 30px 30px;
  }
  #wrapper .service_area > .inner ul li:not(:last-child) {
    margin-bottom: 60px;
  }
}
#wrapper .service_area > .inner ul li p.num {
  font-size: 100px;
  font-weight: bold;
  line-height: 0;
  position: absolute;
  top: 0;
  left: 10px;
  pointer-events: none;
  color: #fff;
  -webkit-text-stroke: 1px #f47907;
}
@media screen and (max-width: 1024px) {
  #wrapper .service_area > .inner ul li p.num {
    font-size: 80px;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .service_area > .inner ul li p.num {
    font-size: 70px;
  }
}
#wrapper .service_area > .inner ul li .icon {
  position: relative;
  display: flex;
  height: 66px;
  margin-bottom: 20px;
  align-items: flex-end;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  #wrapper .service_area > .inner ul li .icon {
    height: auto;
  }
}
#wrapper .service_area > .inner ul li .icon.icon_r:after {
  right: -20px;
}
#wrapper .service_area > .inner ul li .icon:after {
  position: absolute;
  top: -10px;
  right: -10px;
  display: block;
  width: 47px;
  height: 47px;
  content: "";
  border-radius: 50px;
  background: #f4f6f4;
}
#wrapper .service_area > .inner ul li .icon img {
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  #wrapper .service_area > .inner ul li .icon img {
    transform: scale(0.9);
    transform-origin: center bottom;
  }
}
#wrapper .service_area > .inner ul li p.ttl {
  font-size: 24px;
  font-weight: bold;
  width: 100%;
  margin-bottom: 20px;
  color: #008b45;
}
@media screen and (max-width: 1024px) {
  #wrapper .service_area > .inner ul li p.ttl {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .service_area > .inner ul li p.ttl {
    font-size: 18px;
    margin-bottom: 15px;
  }
}
#wrapper .service_area > .inner ul li p.txt {
  font-size: 16px;
  margin-bottom: 30px;
  text-align: left;
}
@media screen and (max-width: 767px) {
  #wrapper .service_area > .inner ul li p.txt {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
#wrapper .service_area > .inner ul li a.btn {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  width: 100%;
  padding: 10px 8px 10px 30px;
  transition: all 0.3s;
  color: #008b45;
  border-radius: 50px;
  background: #f4f6f4;
  align-items: center;
  justify-content: space-between;
}
#wrapper .service_area > .inner ul li a.btn:hover {
  color: #f4f6f4;
  background: #008b45;
}
#wrapper .service_area > .inner ul li a.btn:hover i {
  background: #fff;
}
#wrapper .service_area > .inner ul li a.btn:hover i:after {
  background: #f47907;
}
#wrapper .service_area > .inner ul li a.btn i:after {
  width: 15px;
  height: 8px;
}
#wrapper .service_area > .inner div.btn {
  margin-top: 80px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  #wrapper .service_area > .inner div.btn {
    margin-top: 50px;
  }
}
#wrapper .works_area {
  width: 100%;
  margin-bottom: 170px;
}
@media screen and (max-width: 767px) {
  #wrapper .works_area {
    margin-bottom: 90px;
  }
}
#wrapper .works_area .works_detail {
  padding: 0 3%;
}
@media screen and (max-width: 1024px) {
  #wrapper .works_area .works_detail {
    padding: 0 5% 0;
  }
}
#wrapper .works_area .inner {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 1024px) {
  #wrapper .works_area .inner {
    padding-top: 40px;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .works_area .inner {
    padding-top: 10px;
  }
}
#wrapper .works_area .inner p.deco {
  font-size: 181px;
  font-weight: 600;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
  letter-spacing: 10px;
  pointer-events: none;
  color: #f4f6f4;
}
@media screen and (max-width: 1024px) {
  #wrapper .works_area .inner p.deco {
    font-size: 120px;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .works_area .inner p.deco {
    font-size: 14.2857142857vw;
    top: -10px;
  }
}
#wrapper .works_area .works_slide {
  width: 100%;
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  #wrapper .works_area .works_slide {
    margin-top: 40px;
  }
}
#wrapper .works_area .works_slide .slide_items {
  margin: 0 20px;
}
@media screen and (max-width: 767px) {
  #wrapper .works_area .works_slide .slide_items {
    margin: 0 10px;
  }
}
#wrapper .works_area .works_slide .slide_items img {
  width: 100%;
}
#wrapper .works_area .btn {
  margin-top: 70px;
  text-align: center;
}
#wrapper .recruit_area {
  display: flex;
  width: 100%;
  margin-bottom: 160px;
  padding-left: 3%;
  background: #f4f6f4;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  #wrapper .recruit_area {
    display: block;
    padding: 90px 0 0;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .recruit_area {
    margin-bottom: 90px;
  }
}
#wrapper .recruit_area .txt_box {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  padding: 70px 40px 0 0;
}
@media screen and (max-width: 1024px) {
  #wrapper .recruit_area .txt_box {
    max-width: 90%;
    margin: 0 auto 50px;
    padding: 70px 0 0;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .recruit_area .txt_box {
    padding-top: 25px;
  }
}
#wrapper .recruit_area .txt_box p.deco {
  font-size: 116px;
  font-weight: 600;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: 10px;
  pointer-events: none;
  color: #fdfdfd;
}
@media screen and (max-width: 1024px) {
  #wrapper .recruit_area .txt_box p.deco {
    right: 0;
    left: auto;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .recruit_area .txt_box p.deco {
    font-size: 15.3846153846vw;
    top: -10px;
    right: 0;
    left: auto;
  }
}
#wrapper .recruit_area .txt_box p.txt {
  margin-bottom: 55px;
}
@media screen and (max-width: 767px) {
  #wrapper .recruit_area .txt_box p.txt {
    margin-bottom: 35px;
  }
}
#wrapper .recruit_area .img {
  width: 50%;
  height: 690px;
}
@media screen and (max-width: 1024px) {
  #wrapper .recruit_area .img {
    width: 100%;
    height: auto;
  }
}
#wrapper .recruit_area .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#wrapper .insta_area {
  width: 100%;
  padding: 0 5%;
  margin-bottom: 160px;
}
@media screen and (max-width: 767px) {
  #wrapper .insta_area {
    margin-bottom: 90px;
  }
}
#wrapper .insta_area > .inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}
#wrapper .insta_area > .inner h2 {
  margin-bottom: 30px;
  color: #000;
}
#wrapper .insta_area > .inner h2 i {
  color: #000;
  display: block;
  font-size: 70px;
}
#wrapper .insta_area > .inner iframe {
  width: 100%;
}
#wrapper .news_area {
  width: 100%;
  padding: 0 3%;
  margin-bottom: 100px;
}
@media screen and (max-width: 1024px) {
  #wrapper .news_area {
    padding: 0 5%;
    margin-bottom: 50px;
  }
}
#wrapper .news_area > .inner {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 1024px) {
  #wrapper .news_area > .inner {
    display: block;
  }
}
#wrapper .news_area > .inner .ttl_area {
  width: 27.5%;
}
@media screen and (max-width: 1024px) {
  #wrapper .news_area > .inner .ttl_area {
    width: 100%;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 767px) {
  #wrapper .news_area > .inner .ttl_area {
    margin-bottom: 30px;
  }
}
#wrapper .news_area > .inner .ttl_area h2 {
  letter-spacing: 1px;
}
#wrapper .news_area > .inner .article_area {
  width: 72.5%;
}
@media screen and (max-width: 1024px) {
  #wrapper .news_area > .inner .article_area {
    width: 100%;
  }
}
#wrapper .news_area > .inner .article_area ul {
  width: 100%;
}
#wrapper .news_area > .inner .article_area ul li {
  border-top: 1px solid #d2d2d2;
}
#wrapper .news_area > .inner .article_area ul li:last-child {
  border-bottom: 1px solid #d2d2d2;
}
#wrapper .news_area > .inner .article_area ul li a {
  position: relative;
  display: flex;
  padding: 40px 0;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  #wrapper .news_area > .inner .article_area ul li a {
    padding: 25px 0;
  }
}
#wrapper .news_area > .inner .article_area ul li a:hover i {
  background: #fff;
}
#wrapper .news_area > .inner .article_area ul li a:hover i:after {
  background: #f47907;
}
#wrapper .news_area > .inner .article_area ul li a p.date {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  position: relative;
  top: 4px;
  width: 110px;
  letter-spacing: 0;
  color: #b2b2b2;
}
@media screen and (max-width: 767px) {
  #wrapper .news_area > .inner .article_area ul li a p.date {
    font-size: 15px;
    width: 90px;
  }
}
#wrapper .news_area > .inner .article_area ul li a p.tag {
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  display: flex;
  width: 100px;
  height: 22px;
  color: #fff;
  border-radius: 50px;
  background: #008b45;
  align-items: center;
  justify-content: center;
}
#wrapper .news_area > .inner .article_area ul li a p.news {
  font-size: 16px;
  font-weight: 400;
  width: calc(100% - 110px - 100px);
  padding: 0 60px 0 30px;
}
@media screen and (max-width: 767px) {
  #wrapper .news_area > .inner .article_area ul li a p.news {
    font-size: 14px;
    width: 100%;
    margin-top: 10px;
    padding: 0 45px 0 0;
  }
}
#wrapper .news_area > .inner .article_area ul li a i {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
  border: 1px solid #f47907;
}
#wrapper .news_area > .inner .article_area .more {
  display: flex;
  margin-top: 40px;
  justify-content: flex-end;
}
#wrapper .news_area > .inner .article_area .more a {
  font-size: 14px;
  display: flex;
  align-items: center;
}
#wrapper .news_area > .inner .article_area .more a i {
  position: relative;
  display: block;
  width: 11px;
  height: 11px;
  margin-right: 10px;
  background: #f47907;
}
#wrapper .news_area > .inner .article_area .more a i:before {
  position: absolute;
  top: 0;
  left: 4px;
  width: 3px;
  height: 100%;
  content: "";
  background: #fff;
}
#wrapper .news_area > .inner .article_area .more a i:after {
  position: absolute;
  top: 4px;
  left: 0;
  width: 100%;
  height: 3px;
  content: "";
  background: #fff;
}