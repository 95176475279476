@charset 'utf-8';
@import "../../common/css/mixin";
#wrapper {
 h2 {
  font-size: 20px;
  font-weight: bold;
  position: relative;
  margin-bottom: 10px;
  letter-spacing: 3px;
  color: $or;
  @include break-sp {
   font-size: 18px;
   margin-bottom: 5px;
   letter-spacing: 2.5px;
  }
 }
 h3 {
  font-size: 45px;
  font-weight: bold;
  line-height: 1.4;
  position: relative;
  margin-bottom: 30px;
  letter-spacing: 4px;
  color: $gr;
  @include break {
   font-size: 38px;
  }
  @include break-sp {
   font-size: 21px;
   margin-bottom: 15px;
   letter-spacing: 2.5px;
  }
  + p.txt {
   font-size: 18px;
   line-height: 1.8;
   letter-spacing: 2px;
   @include break-sp {
    font-size: 14px;
    letter-spacing: 1.5px;
   }
  }
 }
 .kv_area {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 95px);
  @include break-sp {
   // height: calc(100vh - 60px);
   height: auto;
  }
  video,
  > img {
   width: 100%;
   height: 100%;
   object-fit: cover;
  }
  p {
   line-height: 1.8;
   position: absolute;
   bottom: 70px;
   left: 7%;
   white-space: nowrap;
   @include break {
    left: 5%;
   }
   @include break-sp {
    bottom: 30px;
   }
   span {
    font-size: 46px;
    font-weight: bold;
    padding: 0 5px;
    letter-spacing: 5px;
    color: #fff;
    background: $gr;
    @include break {
     font-size: 40px;
    }
    @include break-sp {
     font-size: 22px;
    }
   }
   img {
    @include break-sp {
     width: 80%;
    }
   }
  }
 }
 .lead_area {
  margin: 150px auto;
  text-align: center;
  @include break {
   margin: 100px auto;
  }
  @include break-sp {
   margin: 70px auto;
  }
  h1 {
   font-size: 24px;
   font-weight: bold;
   line-height: 1.8;
   @include break {
    font-size: 20px;
   }
   @include break-sp {
    font-size: 18px;
   }
   span {
    font-size: 34px;
    color: $gr;
    @include break {
     font-size: 30px;
    }
    @include break-sp {
     font-size: 24px;
    }
   }
  }
 }
 .philosophy_area {
  display: flex;
  width: 100%;
  margin-bottom: 180px;
  padding-right: 3%;
  @include break {
   position: relative;
   display: block;
   padding: 0;
  }
  @include break-sp {
   margin-bottom: 90px;
  }
  .img {
   width: 50%;
   min-height: 500px;
   padding-right: 90px;
   text-align: left;
   order: -1;
   @include break {
    width: 100%;
    min-height: 0;
    padding: 0;
   }
   img {
    width: 100%;
    height: 100%;
    object-fit: cover;
   }
  }
  .txt_area {
   position: relative;
   width: 100%;
   max-width: 600px;
   padding-top: 60px;
   @include break {
    max-width: 90%;
    margin: 0 auto 50px;
   }
   @include break-sp {
    padding-top: 30px;
   }
   p.deco {
    font-size: 84px;
    font-weight: 600;
    line-height: 1;
    position: absolute;
    top: 0;
    right: 0;
    letter-spacing: 3px;
    pointer-events: none;
    color: $gy;
    text-shadow: none;
    @include break-sp {
     font-size: 12vw;
    }
   }
   h2 {
    font-size: 18px;
    margin-bottom: 20px;
    @include break-sp {
     margin-bottom: 5px;
    }
   }
   h3 {
    font-size: 45px;
    line-height: 1;
    margin-bottom: 30px;
    @include break-sp {
     font-size: 21px;
     line-height: 1.4;
     margin-bottom: 15px;
    }
   }
   p.txt {
    font-weight: bold;
    margin-bottom: 50px;
    @include break-sp {
     margin-bottom: 30px;
    }
   }
  }
 }
 .service_area {
  overflow: hidden;
  width: 100%;
  margin-bottom: 180px;
  padding: 90px 3% 150px;
  background: url(/index/img/service_bg.gif) center bottom repeat-x $gy;
  @include break {
   padding: 90px 5% 180px;
  }
  @include break-sp {
   margin-bottom: 90px;
   padding-bottom: 90px;
   background-size: 44px;
  }
  > .inner {
   position: relative;
   width: 100%;
   max-width: 1200px;
   margin: 0 auto;
   padding-top: 50px;
   @include break-sp {
    padding-top: 10px;
   }
   p.deco {
    font-size: 180px;
    font-weight: 600;
    line-height: 1;
    position: absolute;
    top: -25px;
    right: -40px;
    text-indent: 20px;
    letter-spacing: 20px;
    pointer-events: none;
    color: #fdfdfd;
    @include break {
     font-size: 120px;
    }
    @include break-sp {
     font-size: calc(100vw / 6.5);
     right: calc(5% - 10px);
     letter-spacing: 10px;
    }
   }
   > p.txt {
    margin-bottom: 110px;
    @include break-sp {
     margin-bottom: 70px;
    }
   }
   ul {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    @include break {
     justify-content: space-between;
    }
    @include break-sp {
     display: block;
    }
    li {
     position: relative;
     display: flex;
     flex-direction: column;
     width: 30%;
     padding: 50px 40px 40px;
     text-align: center;
     border-radius: 5px;
     background: #fff;
     align-items: center;
     justify-content: center;
     @include break2 {
      &:not(:last-child) {
       margin-right: calc(10% / 3);
      }
      &:nth-child(3n) {
       margin-right: 0;
      }
      &:nth-child(n + 4) {
       margin-top: 100px;
      }
     }
     @include break {
      width: 48%;
      &:nth-child(n + 3) {
       margin-top: 80px;
       @include break-sp {
        margin-top: 0;
       }
      }
     }
     @include break-sp {
      width: 100%;
      padding: 40px 30px 30px;
      &:not(:last-child) {
       margin-bottom: 60px;
      }
     }
     p.num {
      font-size: 100px;
      font-weight: bold;
      line-height: 0;
      position: absolute;
      top: 0;
      left: 10px;
      pointer-events: none;
      color: #fff;
      -webkit-text-stroke: 1px $or;
      @include break {
       font-size: 80px;
      }
      @include break-sp {
       font-size: 70px;
      }
     }
     .icon {
      position: relative;
      display: flex;
      height: 66px;
      margin-bottom: 20px;
      align-items: flex-end;
      justify-content: center;
      @include break-sp {
       height: auto;
      }
      &.icon_r {
       &:after {
        right: -20px;
       }
      }
      &:after {
       position: absolute;
       top: -10px;
       right: -10px;
       display: block;
       width: 47px;
       height: 47px;
       content: "";
       border-radius: 50px;
       background: $gy;
      }
      img {
       position: relative;
       z-index: 2;
       @include break-sp {
        transform: scale(0.9);
        transform-origin: center bottom;
       }
      }
     }
     p.ttl {
      font-size: 24px;
      font-weight: bold;
      width: 100%;
      margin-bottom: 20px;
      color: $gr;
      @include break {
       font-size: 20px;
      }
      @include break-sp {
       font-size: 18px;
       margin-bottom: 15px;
      }
     }
     p.txt {
      font-size: 16px;
      margin-bottom: 30px;
      text-align: left;
      @include break-sp {
       font-size: 14px;
       margin-bottom: 20px;
      }
     }
     a.btn {
      font-size: 16px;
      font-weight: 600;
      display: flex;
      width: 100%;
      padding: 10px 8px 10px 30px;
      transition: all 0.3s;
      color: $gr;
      border-radius: 50px;
      background: $gy;
      align-items: center;
      justify-content: space-between;
      &:hover {
       color: $gy;
       background: $gr;
       i {
        background: #fff;
        &:after {
         background: $or;
        }
       }
      }
      i {
       &:after {
        width: 15px;
        height: 8px;
       }
      }
     }
    }
   }
   div.btn {
    margin-top: 80px;
    text-align: center;
    @include break-sp {
     margin-top: 50px;
    }
   }
  }
 }
 .works_area {
  width: 100%;
  margin-bottom: 170px;
  @include break-sp {
   margin-bottom: 90px;
  }
  .works_detail {
   padding: 0 3%;
   @include break {
    padding: 0 5% 0;
   }
  }
  .inner {
   position: relative;
   width: 100%;
   max-width: 1200px;
   margin: 0 auto;
   @include break {
    padding-top: 40px;
   }
   @include break-sp {
    padding-top: 10px;
   }
   p.deco {
    font-size: 181px;
    font-weight: 600;
    line-height: 1;
    position: absolute;
    top: 0;
    right: 0;
    letter-spacing: 10px;
    pointer-events: none;
    color: $gy;
    @include break {
     font-size: 120px;
    }
    @include break-sp {
     font-size: calc(100vw / 7);
     top: -10px;
    }
   }
  }
  .works_slide {
   width: 100%;
   margin-top: 60px;
   @include break-sp {
    margin-top: 40px;
   }
   .slide_items {
    margin: 0 20px;
    @include break-sp {
     margin: 0 10px;
    }
    img {
     width: 100%;
    }
   }
  }
  .btn {
   margin-top: 70px;
   text-align: center;
  }
 }
 .recruit_area {
  display: flex;
  width: 100%;
  margin-bottom: 160px;
  padding-left: 3%;
  background: $gy;
  align-items: center;
  @include break {
   display: block;
   padding: 90px 0 0;
  }
  @include break-sp {
   margin-bottom: 90px;
  }
  .txt_box {
   position: relative;
   width: 100%;
   max-width: 600px;
   margin-left: auto;
   padding: 70px 40px 0 0;
   @include break {
    max-width: 90%;
    margin: 0 auto 50px;
    padding: 70px 0 0;
   }
   @include break-sp {
    padding-top: 25px;
   }
   p.deco {
    font-size: 116px;
    font-weight: 600;
    line-height: 1;
    position: absolute;
    top: 0;
    left: 0;
    letter-spacing: 10px;
    pointer-events: none;
    color: #fdfdfd;
    @include break {
     right: 0;
     left: auto;
    }
    @include break-sp {
     font-size: calc(100vw / 6.5);
     top: -10px;
     right: 0;
     left: auto;
    }
   }
   p.txt {
    margin-bottom: 55px;
    @include break-sp {
     margin-bottom: 35px;
    }
   }
  }
  .img {
   width: 50%;
   height: 690px;
   @include break {
    width: 100%;
    height: auto;
   }
   img {
    width: 100%;
    height: 100%;
    object-fit: cover;
   }
  }
 }
 .insta_area {
  width: 100%;
  padding: 0 5%;
  margin-bottom: 160px;
  @include break-sp {
   margin-bottom: 90px;
  }
  > .inner {
   width: 100%;
   max-width: 1200px;
   margin: 0 auto;
   text-align: center;
   h2 {
    margin-bottom: 30px;
    i {
     color: #000;
     display: block;
     font-size: 70px;
    }
    color: #000;
   }
   iframe {
    width: 100%;
   }
  }
 }
 .news_area {
  width: 100%;
  padding: 0 3%;
  margin-bottom: 100px;
  @include break {
   padding: 0 5%;
   margin-bottom: 50px;
  }
  > .inner {
   display: flex;
   width: 100%;
   max-width: 1200px;
   margin: 0 auto;
   @include break {
    display: block;
   }
   .ttl_area {
    width: 27.5%;
    @include break {
     width: 100%;
     margin-bottom: 40px;
    }
    @include break-sp {
     margin-bottom: 30px;
    }
    h2 {
     letter-spacing: 1px;
    }
   }
   .article_area {
    width: 72.5%;
    @include break {
     width: 100%;
    }
    ul {
     width: 100%;
     li {
      border-top: 1px solid #d2d2d2;
      &:last-child {
       border-bottom: 1px solid #d2d2d2;
      }
      a {
       position: relative;
       display: flex;
       padding: 40px 0;
       flex-wrap: wrap;
       @include break-sp {
        padding: 25px 0;
       }
       &:hover {
        i {
         background: #fff;
         &:after {
          background: $or;
         }
        }
       }
       p.date {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: bold;
        line-height: 1;
        position: relative;
        top: 4px;
        width: 110px;
        letter-spacing: 0;
        color: #b2b2b2;
        @include break-sp {
         font-size: 15px;
         width: 90px;
        }
       }
       p.tag {
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
        display: flex;
        width: 100px;
        height: 22px;
        color: #fff;
        border-radius: 50px;
        background: $gr;
        align-items: center;
        justify-content: center;
       }
       p.news {
        font-size: 16px;
        font-weight: 400;
        width: calc(100% - 110px - 100px);
        padding: 0 60px 0 30px;
        @include break-sp {
         font-size: 14px;
         width: 100%;
         margin-top: 10px;
         padding: 0 45px 0 0;
        }
       }
       i {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0%, -50%);
        border: 1px solid $or;
       }
      }
     }
    }
    .more {
     display: flex;
     margin-top: 40px;
     justify-content: flex-end;
     a {
      font-size: 14px;
      display: flex;
      align-items: center;
      i {
       position: relative;
       display: block;
       width: 11px;
       height: 11px;
       margin-right: 10px;
       background: $or;
       &:before {
        position: absolute;
        top: 0;
        left: 4px;
        width: 3px;
        height: 100%;
        content: "";
        background: #fff;
       }
       &:after {
        position: absolute;
        top: 4px;
        left: 0;
        width: 100%;
        height: 3px;
        content: "";
        background: #fff;
       }
      }
     }
    }
   }
  }
 }
}
